

interface GlobalThemeProps {
    fontFamily?: string;
}

interface TextThemeProps {
    color?: string;
}

interface ButtonThemeProps {
    background?: string;
    padding?: string;
    border?: string;
    borderRadius?: string;
    fontSize?: string;
    color?: string;
    width?: string;
    height?: string;
}

interface InputThemeProps {
    background?: string;
    padding?: string;
    border?: string;
    borderRadius?: string;
    fontSize?: string;
    color?: string;
    width?: string;
}

interface BaseThemeProps {
    global?: GlobalThemeProps
    button?: ButtonThemeProps
    text?: TextThemeProps
    input?: InputThemeProps
}


export const LightTheme = (props?: BaseThemeProps) => {
    return {
        global: {
            fontFamily: props?.global?.fontFamily || 'Avenir',
        },
        button: {
            background: props?.button?.background || 'rgba(39, 61, 79, 1)',
            padding: props?.button?.padding || '0px 16px',
            border: props?.button?.border || 'solid 1px #000',
            borderRadius: props?.button?.borderRadius || '4px',
            fontSize: props?.button?.fontSize || '13px',
            color: props?.button?.color || '#fff',
            width: props?.button?.width || 'auto',
            height: props?.button?.height || 'auto'
        },
        text: {
            color: props?.text?.color || 'inherit'
        },
        input: {
            background: props?.input?.background || '#fff',
            padding: props?.input?.padding || '8px 0px',
            border: props?.input?.border || 'solid 1px #000',
            borderRadius: props?.input?.borderRadius || '4px',
            fontSize: props?.input?.fontSize || '13px',
            color: props?.input?.color || '#000',
            width: props?.input?.width || 'auto'
        }
    }
}

