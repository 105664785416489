import { StyledDescription, StyledDescriptionContainer, StyledTitle, StyledTitleContainer } from "./styled"


const Title = () => {
    return (
        <StyledTitleContainer>
            <StyledTitle>
                {'The Most Creative Place on Earth'}
            </StyledTitle>
            <StyledDescriptionContainer>
                <StyledDescription>
                    {'Join the world’s leading social network for artists, galleries, collectors, patrons, and art world professionals'}
                </StyledDescription>
            </StyledDescriptionContainer>
        </StyledTitleContainer>
    )
}

export default Title