import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, OAuthProvider } from "firebase/auth";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

// Initialize Analytics and get a reference to the service
if (process.env.REACT_APP_ENV === 'production') getAnalytics(app);

const emailSignup = async (email: string, password: string, name: string) => {
    return createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            return {data: {...userCredential.user, displayName: name}, status: 200}
            
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;
            return {data: errorMessage.user, status: errorCode}
    });
}

const googleSignIn = async () => {
    const provider = new GoogleAuthProvider()
    return await signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result) as any
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // IdP data available using getAdditionalUserInfo(result)
            return {data: user, status: 200}
        }).catch((error) => {
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            return {message: error.message, data: {email, credential}, status: 400}
  });
}

const appleSignIn = async () => {
    const provider = new OAuthProvider('apple.com');
    provider.addScope('email');
    provider.addScope('name');  

    return await signInWithPopup(auth, provider)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access the Google API.
            // const credential = GoogleAuthProvider.credentialFromResult(result) as any
            // const token = credential.accessToken;
            // The signed-in user info.
            const user = result.user;
            // Apple credential
            // const credential = OAuthProvider.credentialFromResult(result);
            // const accessToken = credential?.accessToken;

            return {data: user, status: 200}
        }).catch((error) => {
            console.log(error)
            // The email of the user's account used.
            const email = error.customData.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            return {message: error.message, data: {email, credential}, status: 400}
  });
}

export {auth, emailSignup, googleSignIn, appleSignIn} 

