import { useQuery } from 'react-query'
import ApiService from '../services/api.service'


export const useSignupCodes = () => {
    const endpoint = '/codes'
    const { isLoading, isError, data, refetch } = useQuery(`signup-codes`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useAccountAudit = () => {
    const endpoint = '/account-audit'
    const { isLoading, isError, data, refetch } = useQuery(`account-audit`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}


export const useAccount = () => {
    const endpoint = '/account'
    const { isLoading, isError, data, refetch } = useQuery(`account`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}

export const useFooter = () => {
    const endpoint = '/web/footer'
    const { isLoading, isError, data, refetch } = useQuery(`web-footer`, () => ApiService.get(endpoint))

    return {
        isLoading,
        isError,
        data,
        refetch
    }
}
