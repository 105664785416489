import styled from 'styled-components'
import { useResponsify } from '../../utils/responsive.util';


interface InputContainerProps {

}


export const StyledInputContainer = styled.div<InputContainerProps>`
   position: relative;
`


interface InputProps {
    theme?: any;
    padding?: string;
    width?: string;
    background?: string;
    fontSize?: string;
    border?: string;
    borderRadius?: string;
    invalid?: boolean;
    outline?: string;
}

export const StyledInput = styled.input<InputProps>`
    font-family: ${props => props.theme.global.fontFamily};
    background: ${props => props.background || props.theme.input.background};
    padding: ${props => props.padding || props.theme.input.padding};
    width: ${props => props.width || props.theme.input.width};
    font-size: ${props => props.fontSize || props.theme.input.fontSize};
    border: ${props => props.border || props.theme.input.border};
    border-radius: ${props => props.borderRadius || props.theme.input.borderRadius};
    color: ${props => props.color || props.theme.input.color};
    text-indent: 12px;
    font-weight: 500;

    &:focus {
        outline: ${props => props.outline || props.border || props.theme.input.border};
        border: 1px solid rgba(255, 255, 255, 0);
    }

    ${props => props.invalid && `
        border: solid 2px #EA4335;
    `}

    @media (min-height: 768px) {
        padding: ${props => props.padding || props.theme.input.padding};
    }

    @media (min-width: 1024px) {
    }
`

interface LabelProps {
    theme?: any;
    invalid?: boolean;
    isFocus?: boolean;
    value?: any;
    border?: string;
}


export const StyledLabel = styled.div<LabelProps>`
    position: absolute;
    top: -10px;
    left: 24px;
    padding: 0px 12px;
    background: ${props => (props.isFocus || props.value) ? '#fff' : 'none'};
    color: ${props => props.isFocus ? '#2f36b1' : props.border || props.theme.input.border};
    height: ${props => (props.isFocus || props.value) ? '24px' : '0px'};
    overflow: hidden;
    transition: all .2s ease-in;
    border-radius: 4px;
    font-size: 12px;

    ${props => props.invalid && !props.isFocus && `
        color: #EA4335;
    `}
`

interface ValidationMessageContainerProps {

}


export const StyledValidationMessageContainer = styled.div<ValidationMessageContainerProps>`
    position: absolute;
    left: 12px;
    bottom: -20px;

    @media (min-width: 768px) {
        left: 24px;
        bottom: -20px;
    }

    @media (min-width: 1024px) {
    }
`

interface ValidationMessageProps {

}


export const StyledValidationMessage = styled.div<ValidationMessageProps>`
    color: #EA4335;
    font-weight: 500;
    font-size: 13px;
`