import { SignupCodesTable } from "../../components/Table/SignupCodesTable"
import { Text } from "../../components/Text"
import { StyledDashboard, StyledDashboardContainer, StyledMetrics, StyledMetricsContainer, StyledViewToggle, StyledViewToggleContainer } from "./styled"
import { Layout } from "../../sections/Layout"
import { AccountAuditTable } from "../../components/Table/AccountAuditTable"
import { Button } from "../../components/Button"
import { useEffect, useState } from "react"
import { useAccountAudit } from "../../hooks/api.hook"
import { StyledMetricContainer } from "../../components/Metric/styled"
import { Metric } from "../../components/Metric"
import { ButterBar } from "../../components/ButterBar"
import { useButterBarStore } from "../../stores/web.store"



const ViewToggle = ({view, setView}: any) => {

    const onClickViewToggle = (value: string) => {
        setView(value)
    }

    return (
        <StyledViewToggleContainer>
            <StyledViewToggle>
                <Button 
                    onClick={() => onClickViewToggle('dashboard')}
                    disabled={view === 'dashboard'}
                    padding={'4px 12px'}
                >Dashboard</Button>
                <Button 
                    onClick={() => onClickViewToggle('codes')}
                    disabled={view === 'codes'}
                    padding={'4px 12px'}
                >Signup Codes</Button>
                <Button 
                    onClick={() => onClickViewToggle('audit')}
                    disabled={view === 'audit'}
                    padding={'4px 12px'}
                >Account Audit</Button>
            </StyledViewToggle>
        </StyledViewToggleContainer>
    )
}

const AccountMetrics = () => {
    const { isLoading, data } = useAccountAudit()
    const [metrics, setMetrics] = useState<any>(null)
    const [verifiedMetrics, setVerifiedMetrics] = useState<any>(null)
    const [unverifiedMetrics, setUnverifiedMetrics] = useState<any>(null)

    useEffect(() => {
        if (!isLoading) {
            const active = {value: 0, title: 'Active'}
            const pending = {value: 0, title: 'Pending'}
            const error = {value: 0, title: 'Error'}
            const verified = {value: 0, title: 'Verified'}
            const unverified = {value: 0, title: 'Unverified'}
            
            const verifiedArtist = {value: 0, title: 'Artist'}
            const verifiedGallery = {value: 0, title: 'Gallery'}
            const verifiedMuseum = {value: 0, title: 'Museum'}

            const verifiedCollector = {value: 0, title: 'Collector'}
            const verifiedArtAdvisor = {value: 0, title: 'Art Advisor'}
            const verifiedArtCritic = {value: 0, title: 'Art Critic'}
            const verifiedArtDealer = {value: 0, title: 'Art Dealer'}
            const verifiedArtWriter = {value: 0, title: 'Art Writer'}
            const verifiedContentCreator = {value: 0, title: 'Content Creator'}
            const verifiedCurator = {value: 0, title: 'Curator'}
            const verifiedInteriorDesigner = {value: 0, title: 'Interior Designer'}


            const unverifiedArtist = {value: 0, title: 'Artist'}
            const unverifiedGallery = {value: 0, title: 'Gallery'}
            const unverifiedMuseum = {value: 0, title: 'Museum'}

            const unverifiedCollector = {value: 0, title: 'Collector'}
            const unverifiedArtAdvisor = {value: 0, title: 'Art Advisor'}
            const unverifiedArtCritic = {value: 0, title: 'Art Critic'}
            const unverifiedArtDealer = {value: 0, title: 'Art Dealer'}
            const unverifiedArtWriter = {value: 0, title: 'Art Writer'}
            const unverifiedContentCreator = {value: 0, title: 'Content Creator'}
            const unverifiedCurator = {value: 0, title: 'Curator'}
            const unverifiedInteriorDesigner = {value: 0, title: 'Interior Designer'}
            

            data?.data.data.forEach((account: any) => {
                if (account.status === 'active' && account.role !== 'admin') {
                    active.value += 1
                }

                if (account.status === 'pending' && account.role !== 'admin') {
                    pending.value += 1
                }

                if (account.status === 'error' && account.role !== 'admin') {
                    error.value += 1
                }
                if (account.is_verified) {
                    if (account.role === 'collector') {
                        verifiedCollector.value += 1
                    }
                    if (account.role === 'artist') {
                        verifiedArtist.value += 1
                    }
                    if (account.role === 'gallery') {
                        verifiedGallery.value += 1
                    }
                    if (account.role === 'museum') {
                        verifiedMuseum.value += 1
                    }
                    if (account.role === 'art_advisor') {
                        verifiedArtAdvisor.value += 1
                    }
                    if (account.role === 'art_critic') {
                        verifiedArtCritic.value += 1
                    }
                    if (account.role === 'art_dealer') {
                        verifiedArtDealer.value += 1
                    }
                    if (account.role === 'art_writer') {
                        verifiedArtWriter.value += 1
                    }
                    if (account.role === 'content_creator') {
                        verifiedContentCreator.value += 1
                    }
                    if (account.role === 'curator') {
                        verifiedCurator.value += 1
                    }
                    if (account.role === 'interior_designer') {
                        verifiedInteriorDesigner.value += 1
                    }

                    if (account.role !== 'admin')
                        verified.value += 1
                } else {
                    if (account.role === 'collector') {
                        unverifiedCollector.value += 1
                    }
                    if (account.role === 'artist') {
                        unverifiedArtist.value += 1
                    }
                    if (account.role === 'gallery') {
                        unverifiedGallery.value += 1
                    }
                    if (account.role === 'museum') {
                        unverifiedMuseum.value += 1
                    }
                    if (account.role === 'art_advisor') {
                        unverifiedArtAdvisor.value += 1
                    }
                    if (account.role === 'art_critic') {
                        unverifiedArtCritic.value += 1
                    }
                    if (account.role === 'art_dealer') {
                        unverifiedArtDealer.value += 1
                    }
                    if (account.role === 'art_writer') {
                        unverifiedArtWriter.value += 1
                    }
                    if (account.role === 'content_creator') {
                        unverifiedContentCreator.value += 1
                    }
                    if (account.role === 'curator') {
                        unverifiedCurator.value += 1
                    }
                    if (account.role === 'interior_designer') {
                        unverifiedInteriorDesigner.value += 1
                    }

                    if (account.role !== 'admin')
                        unverified.value += 1
                }
            })

            setMetrics([
                verified,
                unverified,
                active,
                pending,
                error
            ])

            setVerifiedMetrics([
                verifiedArtist,
                verifiedCollector,
                verifiedGallery,
                verifiedArtAdvisor,
                verifiedInteriorDesigner,
                verifiedMuseum,
                verifiedArtCritic,
                verifiedArtDealer,
                verifiedArtWriter,
                verifiedContentCreator,
                verifiedCurator
            ])

            setUnverifiedMetrics([
                unverifiedArtist,
                unverifiedCollector,
                unverifiedGallery,
                unverifiedArtAdvisor,
                unverifiedInteriorDesigner,
                unverifiedMuseum,
                unverifiedArtCritic,
                unverifiedArtDealer,
                unverifiedArtWriter,
                unverifiedContentCreator,
                unverifiedCurator
            ])


        }
    }, [isLoading])

    if (metrics === null) return <div/>

    return (
        <StyledMetricsContainer>
            <StyledMetricContainer>
                <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px">Total</Text>
                <StyledMetrics>
                    {metrics.map((metric: any) => {
                        return <Metric title={metric.title} value={metric.value} />
                    })}
                </StyledMetrics>
            </StyledMetricContainer>
            <StyledMetricContainer>
                <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px">Verified</Text>
                <StyledMetrics>
                    {verifiedMetrics.map((metric: any) => {
                        return <Metric title={metric.title} value={metric.value} />
                    })}
                </StyledMetrics>
            </StyledMetricContainer>
            <StyledMetricContainer>
                <Text fontSize="32px" color="#fff" textAlign="center" padding="12px 0px">Unverified</Text>
                <StyledMetrics>
                    {unverifiedMetrics.map((metric: any) => {
                        return <Metric title={metric.title} value={metric.value} />
                    })}
                </StyledMetrics>
            </StyledMetricContainer>
        </StyledMetricsContainer>
    )
}


const AdminDashboard = () => {
    const [view, setView] = useState('dashboard')
    const isButterBar = useButterBarStore((state: any) => state.isButterBar)
    return (
        <Layout>
            <StyledDashboardContainer>
                <StyledDashboard>
                    <ViewToggle view={view} setView={setView} />

                    {view === 'dashboard' && <AccountMetrics />}
                    {view === 'codes' && <SignupCodesTable />}
                    {view === 'audit' && <AccountAuditTable />}
                </StyledDashboard>
                {isButterBar && <ButterBar>{isButterBar}</ButterBar>}
            </StyledDashboardContainer>   
        </Layout>
    )
}

export default AdminDashboard