import styled from 'styled-components'


interface SearchBarContainerProps {

}


export const StyledSearchBarContainer = styled.div<SearchBarContainerProps>`
    position: relative;
    width: 100%;
`

interface SearchBarProps {
    
}


export const StyledSearchBar = styled.input<SearchBarProps>`
    width: inherit;
    padding: 12px 0px 12px 12px;
    border: solid 1px grey;
    border-radius: 4px;
`

interface SearchBarIconProps {

}


export const StyledSearchBarIcon = styled.div<SearchBarIconProps>`
    position: absolute;
    top: 12px;
    right: 0;
`

