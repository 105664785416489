import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from '@fortawesome/free-solid-svg-icons'

import { Button } from "../../components/Button"
import { StyledAccountMenu, StyledAccountMenuContainer, StyledMenuItem, StyledNavigationMenuLeftSection, StyledNavigationMenuRightSection, StyledTopNav, StyledTopNavContainer, StyledTopNavLeftSection, StyledTopNavMiddleSection, StyledTopNavRightSection, StyledTopNavVerticalAlign, StyledTopNavWrapper, StyledTopNavigationMenu } from "./styled"
import { SearchBar } from "../../components/SearchBar"
import { Logo } from "../../components/Logo"
import { Text } from "../../components/Text"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { signout } from "../../services/auth.service"



type MenuItemProps = {
    title: string;
    textAlign?: string;
    path: string;
}


const MenuItem = ({title, path, textAlign}: MenuItemProps) => {
    const navigate = useNavigate()
    return (
        <StyledMenuItem textAlign={textAlign}>
            <Button onClick={() => navigate(path)} noStyle><Text fontSize={'20px'}>{title}</Text></Button>
        </StyledMenuItem>
    )
}

type TopNavProps = {
    includeNavigation?: boolean;
}


const TopNav = ({includeNavigation}: TopNavProps) => {
    const [showAccountSettings, setShowAccountSettings] = useState(false)

    const onClickSignout = () => {
        signout()
    }

    return (
        <StyledTopNavContainer>
            <StyledTopNavWrapper>
                <StyledTopNav>
                    <StyledTopNavLeftSection>
                        <StyledTopNavVerticalAlign>
                            <SearchBar />
                        </StyledTopNavVerticalAlign>
                    </StyledTopNavLeftSection>
                    <StyledTopNavMiddleSection>
                        <StyledTopNavVerticalAlign>
                            <Logo />
                        </StyledTopNavVerticalAlign>
                    </StyledTopNavMiddleSection>
                    <StyledTopNavRightSection>
                        <StyledTopNavVerticalAlign>
                            <Button noStyle color={'#000'} onClick={() => setShowAccountSettings(!showAccountSettings)}>
                                <FontAwesomeIcon icon={faUser} fontSize={24}/>
                            </Button>
                            {showAccountSettings && <StyledAccountMenuContainer>
                                <StyledAccountMenu>
                                    <Button color={'#000'} noStyle onClick={onClickSignout}>Signout</Button>
                                </StyledAccountMenu>
                            </StyledAccountMenuContainer>}
                        </StyledTopNavVerticalAlign>
                    </StyledTopNavRightSection>
                </StyledTopNav>
                {includeNavigation && <StyledTopNavigationMenu>
                    <StyledNavigationMenuLeftSection>
                        <MenuItem title={'For You'} path={'/for-you'}/>
                        <MenuItem title={'Artists'} path={'/artists'}/>
                        <MenuItem title={'Collectors'} path={'/collectors'}/>
                    </StyledNavigationMenuLeftSection>
                    <StyledNavigationMenuRightSection>
                        <MenuItem title={'Artworks'} textAlign={'right'} path={'/artworks'}/>
                        <MenuItem title={'Collaborations'} textAlign={'right'} path={'/collaborations'}/>
                        <MenuItem title={'Exhibitions'} textAlign={'right'} path={'/exhibitions'}/>
                        <MenuItem title={'Auctions'} textAlign={'right'} path={'/auctions'}/>
                    </StyledNavigationMenuRightSection>
                </StyledTopNavigationMenu>}
            </StyledTopNavWrapper>
            
        </StyledTopNavContainer>
    )
}

export default TopNav