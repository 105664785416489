import styled from 'styled-components'

interface ButterBarContainerProps {
    background?: string;
    color?: string;
}


export const StyledButterBarContainer = styled.div<ButterBarContainerProps>`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    color: ${props => props.color || '#000'};
    background: ${props => props.background || '#fff'};
    display: table;
    border-bottom: solid 1px #000;
`

interface ButterBarProps {

}

export const StyledButterBar = styled.div<ButterBarProps>`
    display: table-cell;
    width: 100%;
    margin: auto;
    text-align: center;
    vertical-align: middle;
    height: 60px;
    position: relative;
`

interface ButterBarCloseProps {

}

export const StyledButterBarClose = styled.div<ButterBarCloseProps>`
    position: absolute;
    right: 12px;
    top: 18px;

`