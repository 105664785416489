import styled from 'styled-components'


interface SelectContainerProps {
    width?: string;
    padding?: string;
    background?: string;
    border?: string;
    color?: string;
    borderRadius?: string;
    outline?: string;
}

export const StyledSelectContainer = styled.div<SelectContainerProps>`

    & .select__input {
        text-indent: 12px;
        
    }

    

    & .select__control {
        text-align: left;
        padding: ${props => props.padding || props.theme.input.padding};
        border: ${props => props.border || 'solid 1px #000'};
        border-radius: ${props => props.borderRadius || '4px'};
        font-size: 16px;
        width: ${props => props.width || props.theme.input.width};
        margin: auto;
        text-indent: 12px;
        background: ${props => props.background || props.theme.input.background};
        
    }

    & .select__single-value {
        color: ${props => props.color || '#000'};
        font-weight: 500;
    }

    & .select__control:hover {
        border: ${props => props.border || 'solid 1px #000'};
    }

    & .select__control:focus {
        outline: ${props => props.outline || props.border || '2px solid #2f36b1'}
        border: ${props => props.border || 'solid 1px #000'};
    }
    

    & .select__menu {
        text-align: left;
    }

    & .select__value-container {
        padding: 0;
    }

     & .select__input-container {
        padding: 0;
        margin: 0;
     }

     @media (min-height: 768px) {
        & .select__control {
            padding: ${props => props.padding || props.theme.input.padding};
        }

        
    }

    @media (min-width: 1024px) {

    }
`


