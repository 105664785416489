import { ReactNode } from "react";
import { StyledText } from "./styled"
import { useThemeStore } from "../../stores/web.store";

type TextProps = {
    children?: ReactNode;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    textAlign?: string;
    textDecoration?: string;
    padding?: string;
}


const Text = (props: TextProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    return (
        <StyledText 
            theme={theme}
            color={props.color}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
            padding={props.padding}
            textAlign={props.textAlign}
            textDecoration={props.textDecoration}
        >
            {props.children}
        </StyledText>
    )
}

export default Text