import { useNavigate } from "react-router-dom"
import { Button } from "../Button"
import { Text } from "../Text"


type LogoProps = {
    height?: string;
    width?: string;
    fontSize?: string;
}


const Logo = (props: LogoProps) => {
    const navigate = useNavigate()
    return (
        <Button onClick={() => navigate('/')} height={props.height || '40px'} width={props.width || '40px'} border={'solid 3px #000'} noStyle>
            <Text 
                fontSize={props.fontSize || '20px'}
                fontWeight={'500'}
                color={'#000'}
            >Y</Text>
        </Button>
    )
}

export default Logo