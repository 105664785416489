import api from "./api.service";
import { getAnalytics, logEvent } from "firebase/analytics";
import { auth } from './firebase.service'
import { appleSignIn, emailSignup, googleSignIn } from "./firebase.service";

const analytics = getAnalytics();

type SignupProps = {
    type: string;
    email?: string;
    password?: string;
    name?: string;
    role?: string;
    code: string;
}

const signin = async (props: SignupProps) => {
    let resp;

    if (props.type === 'email' && props.email && props.password && props.name) {
        resp = await emailSignup(props.email, props.password, props.name)
    }

    if (props.type === 'google') {
        resp = await googleSignIn()
    }

    if (props.type === 'apple') {
        resp = await appleSignIn()
    }


    if (resp?.status === 200) {

        const apiResp = await api.post(`/signin`, {})

        const role = apiResp?.data?.data ? apiResp?.data?.data.role : props.role
        let event = 'sign_in'

        if (process.env.REACT_APP_ENV === 'production') logEvent(analytics, event, {
            uid: resp.data.uid,
            role: role,
            code: props.code, 
            provider: props.type, 
            status: apiResp?.data.status, 
            message: apiResp?.data.message
        });

        return apiResp.data
    }

    return resp

    
}


const signup = async (props: SignupProps) => {
    let resp;

    if (props.type === 'email' && props.email && props.password && props.name) {
        resp = await emailSignup(props.email, props.password, props.name)
    }

    if (props.type === 'google') {
        resp = await googleSignIn()
    }

    if (props.type === 'apple') {
        resp = await appleSignIn()
    }

    console.log(resp)

    if (resp?.status === 200) {
        const apiPayload = {
            uid: resp.data.uid,
            name: resp.data.displayName,
            email: resp.data.email,
            role: props.role
        }
        
    
        const signupEndpoint = props.code ? `/signup?code=${props.code}` : `/signup`
        const apiResp = await api.post(signupEndpoint, apiPayload)

        console.log(apiResp)

        const role = apiResp?.data?.data ? apiResp?.data?.data.role : props.role
        let event = 'sign_up'

        if (process.env.REACT_APP_ENV === 'production') logEvent(analytics, event, {
            uid: resp.data.uid,
            role: role,
            code: props.code, 
            provider: props.type, 
            status: apiResp?.data.status, 
            message: apiResp?.data.message
        });

        return apiResp.data
    }

    return resp

    
}

const signout = async () => {
    await auth.signOut()
    window.location.href = '/'
}

export {signup, signin, signout}