import { useEffect, useState } from "react"
import { useSignupCodes } from "../../../hooks/api.hook"
import Table from "../Table"
import { Text } from "../../Text";
import { StyledCodeGenerator, StyledCopyButton, StyledCopyButtonCell, StyledCopyButtonContainer, StyledDeleteContainer, StyledDeleteModal, StyledGenerateCodeButton, StyledGenerateCodeInput, StyledPreDeleteButton, StyledPreDeleteButtonCell, StyledPreDeleteButtonContainer, StyledSignupCodesTable, StyledStatusCell } from "./styled"
import {ReactComponent as AddIcon} from '../../../img/add.svg';
import {ReactComponent as CopyIcon} from '../../../img/copy.svg';
import {ReactComponent as StatusIcon} from '../../../img/status.svg';
import {ReactComponent as DeleteIcon} from '../../../img/delete.svg';
import ApiService from "../../../services/api.service"
import { Modal } from "../../Modal";
import { Button } from "../../Button";
import api from "../../../services/api.service";
import { useButterBarStore, useSuperAdminAccessStore } from "../../../stores/web.store";


const GenerateCodeButton = ({setInputValue, inputValue, tableData, setTableData}: any) => {
    const setIsButterBar = useButterBarStore((state: any) => state.setIsButterBar)
    const [isHover, setIsHover] = useState(false)
    const onClick = async () => {
        if (inputValue) {
            const resp = await ApiService.post('/codes', {email: inputValue})
            if (resp?.data.status === 200) {
                setTableData([resp.data.data, ...tableData])
                setInputValue('')
            } else {
                setIsButterBar(resp?.data.message)
            }
        }
        
    }
    return (
        <StyledGenerateCodeButton 
            onClick={onClick} 
            onMouseEnter={() => setIsHover(true)} 
            onMouseLeave={() => setIsHover(false)}
        >
            <AddIcon fill={isHover ? '#9fbbd0' : '#fff'}/>
        </StyledGenerateCodeButton>
    )
}

const GenerateCodeInput = ({setInputValue, inputValue, tableData, setTableData}: any) => {
    const setIsButterBar = useButterBarStore((state: any) => state.setIsButterBar)
    const onChange = (e: any) => {
        setInputValue(e.target.value)
    }

    const onKeyDown = async (e: any) => {
        if (e.key === 'Enter' && inputValue) {
            const resp = await ApiService.post('/codes', {email: inputValue})
            if (resp?.data.status === 200) {
                setTableData([resp.data.data, ...tableData])
                setInputValue('')
            } else {
                setIsButterBar(resp?.data.message)
            }
            
        }
    }
    return (
        <StyledGenerateCodeInput 
            type={'email'}
            onChange={onChange} 
            onKeyDown={onKeyDown}
            value={inputValue}
            placeholder={'Email Address'}
        />
    )
}

const CodeGenerator = ({inputValue, setInputValue, tableData, setTableData}: any) => {
    
    return (
        <StyledCodeGenerator>
            <Text color={'#fff'} fontSize={'13px'} padding={'12px 0px'}>Generate Code</Text>
            <GenerateCodeInput 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                tableData={tableData} 
                setTableData={setTableData}
            />
            <GenerateCodeButton 
                setInputValue={setInputValue} 
                inputValue={inputValue} 
                tableData={tableData} 
                setTableData={setTableData}
            />
        </StyledCodeGenerator>
    )
}

const CopyButtonCell = ({data}: any) => {
    const [isHover, setIsHover] = useState(false)
    const [isClicked, setIsClicked] = useState(false)
    const onClick = (value: any) => {
        setIsClicked(true)
        navigator.clipboard.writeText(`${window.location.protocol}//${window.location.host}?code=${value}`)

        setTimeout(() => {
            setIsClicked(false)
        }, 400);
        
    }

    return (
        <StyledCopyButtonCell isClicked={isClicked}>
            <div>{data.value}</div>
            <StyledCopyButtonContainer>
                <StyledCopyButton 
                    onClick={() => onClick(data.value)}
                    onMouseEnter={() => setIsHover(true)} 
                    onMouseLeave={() => setIsHover(false)}
                >
                    <CopyIcon fill={isHover ? '#9fbbd0' : '#fff'} />
                </StyledCopyButton>
            </StyledCopyButtonContainer>
        </StyledCopyButtonCell>
    )
}

const StatusCell = ({data}: any) => {
    const color = data.value === 'active' ? '#58A65C' : data.value === 'pending' ? '#F1BF42' : '#D85140'
    return (
        <StyledStatusCell>
            <StatusIcon fill={color} />
        </StyledStatusCell>
    )
}

const ActionsCell = ({data}: any) => {
    const isSuperAdmin = useSuperAdminAccessStore((state: any) => state.isSuperAdmin)
    const [isHover, setIsHover] = useState(false)
    const [isClicked, setIsClicked] = useState(false)

    const onClickDelete = async () => {
        await api.delete(`/codes/${data.data.id}`)
        data.api.applyTransaction({ remove: [data.data] }) 
        setIsClicked(false)
    }

    return (
        <div>
            {isSuperAdmin && <StyledPreDeleteButtonCell isClicked={isClicked}>
                <StyledPreDeleteButtonContainer>
                    <StyledPreDeleteButton
                        onClick={() => setIsClicked(true)}
                        onMouseEnter={() => setIsHover(true)} 
                        onMouseLeave={() => setIsHover(false)}
                    >
                        <DeleteIcon fill={isHover ? '#EA4335' : '#fff'} />
                    </StyledPreDeleteButton> 
                </StyledPreDeleteButtonContainer>
                {isClicked && <Modal setModal={setIsClicked} height={'fit-content'}>
                    <StyledDeleteModal>
                        <Text fontSize={'20px'}>Are You Sure?</Text>
                        <Text>Permenantly Delete <b>{data.data.email}</b> signup code.</Text>
                        <StyledDeleteContainer>
                            <Button padding={'4px 20px'} onClick={() => setIsClicked(false)}>Cancel</Button>
                            <Button padding={'4px 20px'} onClick={onClickDelete} background={'#EA4335'} border={'solid 1px #EA4335'}>Delete</Button>
                        </StyledDeleteContainer>
                    </StyledDeleteModal>
                </Modal>}
            </StyledPreDeleteButtonCell>}
        </div>
    )
}


const SignupCodesTable = () => {
    const { isLoading, data} = useSignupCodes()
    const [tableData, setTableData] = useState<any>(null)
    const [columns, setColumns] = useState<any>(null)
    const [inputValue, setInputValue] = useState('')

    useEffect(() => {
        if (!isLoading) {
            setTableData(data?.data.data)
            if (data?.data.data.length > 0) {
                const activeColumns = ['status', 'email', 'code', 'actions']

                setColumns(activeColumns.map((key: string) => {
                    if (key === 'code') {
                        return {
                            field: key,
                            flex: .5,
                            filter: 'agTextColumnFilter',
                            cellRenderer: (data: any) => {
                                return (
                                    <CopyButtonCell data={data} />
                                )
                            }
                        }
                    }
                    if (key === 'status') {
                        return {
                            field: key,
                            headerName: '',
                            width: 60,
                            filter: 'agTextColumnFilter',
                            cellRenderer: (data: any) => {
                                return (
                                    <StatusCell data={data} />
                                )
                            }
                        }
                    }
                    if (key === 'actions') {
                        return {
                            field: key,
                            headerName: 'Actions',
                            width: 120,
                            cellRenderer: (data: any) => {
                                return (
                                    <ActionsCell data={data} />
                                )
                            }
                        }
                    }
                    return {field: key, flex: 1, filter: 'agTextColumnFilter'}
                }) as any)
            } else {
                setColumns([])
            }
            
        }
    }, [isLoading])


    if (tableData === null ) return <div/>
    
    return (
        <StyledSignupCodesTable>
            <CodeGenerator 
                inputValue={inputValue}
                setInputValue={setInputValue}
                tableData={tableData} 
                setTableData={setTableData}
            />
            <Text 
                fontSize={'20px'} 
                fontWeight={'300'} 
                padding={'12px 4px'}
                color={'#fff'}
            >{'Signup Codes'}</Text>
            <Table data={tableData} columns={columns} searchPlaceholder={'Search Codes...'} hasSearchBar/>
        </StyledSignupCodesTable>
    )
}

export default SignupCodesTable