import ReactSelect from 'react-select'
import { StyledSelectContainer } from './styled';


type SelectProps = {
    options?: any;
    onChange?: any
    placeholder?: string;
    width?: string;
    color?: string;
    background?:string;
    border?: string;
    borderRadius?: string;
    padding?: string;
    outline?: string;
}

const Select = ({options, onChange, placeholder, width, background, padding, border, borderRadius, outline, color}: SelectProps) => {
    return (
        <StyledSelectContainer
            width={width}
            padding={padding}
            background={background}
            border={border}
            color={color}
            outline={outline}
            borderRadius={borderRadius}
        >
            <ReactSelect 
                classNamePrefix="select"
                options={options} 
                onChange={onChange}
                placeholder={placeholder}
                isSearchable={false}
                
            />
        </StyledSelectContainer>
        
    )
}

export default Select
