import styled from 'styled-components'


interface MetricContainerProps {

}


export const StyledMetricContainer = styled.div<MetricContainerProps>`

`

interface MetricProps {

}


export const StyledMetric = styled.div<MetricProps>`
    background: #12232E;
    padding: 24px;
    border-radius: 4px;
`



