import { Suspense, useEffect } from 'react'
import { auth } from './services/firebase.service'
import { Routes, Route} from 'react-router-dom'
import { Home } from './pages/Home'
import { Artists } from './pages/Artists'
import { Landing } from './pages/Landing'
import { AdminDashboard } from './pages/AdminDashboard'
import { useAccessStore, useLandingPageStateStore, useSuperAdminAccessStore } from './stores/web.store'
import api from './services/api.service'


const Routing = () => {
  const access = useAccessStore((state: any) => state.access)
  const setAccess = useAccessStore((state: any) => state.setAccess)
  const activeState = useLandingPageStateStore((state: any) => state.activeState)

  const setIsSuperAdmin = useSuperAdminAccessStore((state: any) => state.setIsSuperAdmin)

  useEffect(() => {
    const fetch =  async () => {
      const resp = await api.get('/account')

      if (resp.data?.data?.role === 'admin') {
        setAccess(true)

        if (resp.data?.data?.email.includes('pybymantis@gmail.com') || resp.data?.data?.email.includes('danielkennedy@primaryyellow.com') || resp.data?.data?.email.includes('jarrenframe@primaryyellow.com')) {
          setIsSuperAdmin(true)
        }
      } else {
        setAccess(false)
      }
    }

    auth.onAuthStateChanged((user) => {
      if (user) {
        fetch()
      } else {
        setAccess(false)
      }
      
    })
  }, [auth.currentUser])


  return (
    <Suspense>
        <Routes>
          <Route path="/" element={access === null ? <div /> : access ? <AdminDashboard /> : <Landing />} />
          {/* <Route path="/home" element={<Home />} />
          <Route path="/artists" element={<Artists />} /> */}
        </Routes> 
    </Suspense>
  )
}

export default Routing;