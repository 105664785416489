import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMagnifyingGlass} from '@fortawesome/free-solid-svg-icons'
import { StyledSearchBar, StyledSearchBarContainer, StyledSearchBarIcon } from "./styled"
import { Button } from "../Button"

type SearchBarProps = {
    placeholder?: string;
    onInput?: any;
    id?: any;
}

const SearchBar = ({id, placeholder, onInput}: SearchBarProps) => {
    return (
        <StyledSearchBarContainer>
            <StyledSearchBar id={id} placeholder={placeholder || "Search Primary Yellow"} onInput={onInput} data-lpignore="true"/>
            <StyledSearchBarIcon>
                <Button noStyle color={'#000'}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                </Button>
            </StyledSearchBarIcon>
        </StyledSearchBarContainer>
    )
}

export default SearchBar