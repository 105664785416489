import styled from 'styled-components'


interface InlineContainerProps {

}


export const StyledInlineContainer = styled.div<InlineContainerProps>`
    display: inline-flex;
    gap: 4px;
    width: 100%;
`

interface BoldProps {

}


export const Bold = styled.b<BoldProps>`
    
`