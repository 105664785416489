import styled from 'styled-components'




interface MainContentContainerProps {

}


export const StyledMainContentContainer = styled.div<MainContentContainerProps>`
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, .7) 70%, rgba(0, 0, 0, .5), rgba(0, 0, 0, .4)),
    url(/img/hero_background.png);
    background-position: center center;
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    width: 100%;
    font-family: Avenir;
    overflow-x: hidden;
    overflow-y: scroll;
`