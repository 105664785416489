import styled from 'styled-components'


interface FooterContainerProps {

}


export const StyledFooterContainer = styled.div<FooterContainerProps>`

`

interface FooterProps {

}


export const StyledFooter = styled.div<FooterProps>`

`