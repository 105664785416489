import { Button } from '../../../../components/Button';
import { ReactComponent as FullLogo } from '../../../../img/fullLogo.svg';
import { useLandingPageStateStore, useResponsiveStore } from '../../../../stores/web.store';
import { Desktop, Laptop } from '../../../../utils/responsive.util';
import { StyledHeader, StyledHeaderContainer, StyledLinks, StyledLinksContainer, StyledLogoContainer } from "./styled"

type LinkButtonProps = {
    title: string;
    onClick: any;
}

const LinkButton = (props: LinkButtonProps) => {
    return (
        <Button 
            fontSize='20px'
            fontWeight='500'
            onClick={props.onClick}
            noStyle 
        >{props.title}</Button>
    )
}

const AboutButton = () => {
    const onClick = () => {

    }
    return (
        <LinkButton title={'About'} onClick={onClick} />
    )
}

const GetAppButton = () => {
    const onClick = () => {

    }
    return (
        <LinkButton title={'Get the App'} onClick={onClick} />
    )
}

const Header = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)
    return (
        <StyledHeaderContainer>
            <StyledHeader>
                <StyledLogoContainer>
                    <Button noStyle onClick={() => setActiveState('initial')}><FullLogo /></Button>
                </StyledLogoContainer>
                {[Desktop, Laptop].includes(mode) && <StyledLinksContainer>
                    <StyledLinks>
                        <AboutButton />
                        <GetAppButton />
                    </StyledLinks>
                </StyledLinksContainer>}
            </StyledHeader>
        </StyledHeaderContainer>
    )
}

export default Header