import { useState } from "react";
import { StyledSlider, StyledSpan, StyledSwitch, StyledSwitchContainer } from "./styled"
import Switch from "react-switch";


type SwitchProps = {
    onChange?: any;
    isChecked?: boolean;
}

const PYSwitch = (props: SwitchProps) => {
   
    return (
        <StyledSwitchContainer>
            <StyledSpan isChecked={props.isChecked === false}>Artist</StyledSpan>
            <Switch 
                onChange={props.onChange}
                checked={props.isChecked || false}
                checkedIcon={false}
                uncheckedIcon={false}
                offColor={'#d1d1d1'}
                onColor={'#d1d1d1'}
                onHandleColor={'#1f1f1f'}
                offHandleColor={'#1f1f1f'}
                height={20}
                width={40}
            />
            <StyledSpan isChecked={props.isChecked === true}>Collector</StyledSpan>
        </StyledSwitchContainer>
    )
}

export default PYSwitch