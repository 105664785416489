import CountUp from 'react-countup';
import { Text } from "../Text";
import { StyledMetric, StyledMetricContainer } from "./styled"


type MetricProps = {
    title?: string;
    value?: any;
}


const Metric = ({title, value}: MetricProps) => {
    return (
        <StyledMetricContainer>
            <StyledMetric>
                <Text textAlign="center" fontSize="32px" color={'#fff'}>{title}</Text>
                <Text textAlign="center" fontSize="60px" fontWeight="600" color={'#fff'}><CountUp end={value} /></Text>
            </StyledMetric>
        </StyledMetricContainer>
    )
}

export default Metric