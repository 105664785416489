import styled from 'styled-components'


interface ModalContainerProps {
    position?: string;
    top?: string;
}


export const StyledModalContainer = styled.div<ModalContainerProps>`
    position: ${props => props.position || 'fixed'};
    top: ${props => props.top || '0'};
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 2;
`


interface ModalProps {
    width?: any;
    height?: any;
    margin?: any;
}


export const StyledModal = styled.div<ModalProps>`
    width: 100%;
    display: table;
    height: ${props => props.height || '100vh'};
    margin: auto;
    

    @media (min-width: 768px) {
        width: ${props => props.width || '500px'};
        margin: auto;
        
    }
    
    @media (min-width: 1600px) {
    }
`

interface ModalContentContainerProps {

}


export const StyledModalContentContainer = styled.div<ModalContentContainerProps>`
    display: table-cell;
    height: 100vh;
    vertical-align: middle;
    
    @media (min-width: 768px) {
        height: fit-content; 
    }

    @media (min-width: 1024px) {

    }
`

interface ModalContentProps {
    height?: any;
}


export const StyledModalContent = styled.div<ModalContentProps>`
    background: rgba(255, 255, 255, .9);
    border-radius: 0px;
    height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    @media (min-width: 768px) {
        border-radius: 8px;
        padding: 12px;
        height: ${props => props.height || '90vh'};
    }

    @media (min-width: 1024px) {
    }
`
