import { ReactNode } from "react"
import { StyledModal, StyledModalContainer, StyledModalContent, StyledModalContentContainer } from "./styled"


type ModalProps = {
    children: ReactNode
    setModal: any
    position?: string;
    height?: any;
    width?: any;
    modalHeight?: any;
    top?: any;
    margin?: any;
}

const Modal = ({setModal, position, height, width, modalHeight, top, margin, children}: ModalProps) => {
    return (
        <StyledModalContainer position={position} top={top}>
            <StyledModal width={width} height={modalHeight} margin={margin}>
                <StyledModalContentContainer>
                    <StyledModalContent height={height}>
                        {children}
                    </StyledModalContent>
                </StyledModalContentContainer>
            </StyledModal>
        </StyledModalContainer>
    )
}

export default Modal