import { useEffect } from "react";
import { useButterBarStore } from "../../stores/web.store";
import { StyledButterBar, StyledButterBarClose, StyledButterBarContainer } from "./styled"
import { Button } from "../Button";
import { ReactComponent as CloseIcon } from '../../img/close.svg';

type ButterBarProps = {
    children: any;
    background?: string;
    color?: string;
}

const ButterBar = ({background, color, children}: ButterBarProps) => {
    const isButterBar = useButterBarStore((state: any) => state.isButterBar)
    const setIsButterBar = useButterBarStore((state: any) => state.setIsButterBar)

    useEffect(() => {
        if (isButterBar) {
            setTimeout(() => {
                setIsButterBar(false)
            }, 5000);
        }
    }, [isButterBar])

    return (
        <StyledButterBarContainer background={background} color={color}>
            <StyledButterBar>
                {children}
                <StyledButterBarClose>
                    <Button noStyle onClick={() => setIsButterBar(false)}><CloseIcon /></Button>
                </StyledButterBarClose>
            </StyledButterBar>
        </StyledButterBarContainer>
    )
}

export default ButterBar