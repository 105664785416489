import { useEffect, useState } from "react"
import { signin, signup } from "../../services/auth.service"
import { useAccessStore, useButterBarStore, useLandingPageStateStore, useResponsiveStore, useSignupCompleteStore, useSignupStore } from "../../stores/web.store"
import { Button } from "../Button"
import { Icon } from "../Icon"
import { Input } from "../Input"
import { Logo } from "../Logo"
import { Text } from "../Text"
import { ReactComponent as AppleLogo } from '../../img/apple.svg';
import { ReactComponent as GoogleLogo } from '../../img/google.svg';
import { ReactComponent as VisibleIcon } from '../../img/visible.svg';
import { ReactComponent as NotVisibleIcon } from '../../img/notVisible.svg';
import {  StyledAccountTypeContainer, StyledBackToSignIn, StyledEmailInputContainer, StyledFullNameContainer, StyledPasswordInputContainer, StyledProviders, StyledProvidersContainer, StyledRoleSelectContainer, StyledRoleSwitchContainer, StyledShowPassword, StyledSignup, StyledSignupButtonContainer, StyledSignupContainer, StyledSignupForm, StyledSignupFormContainer, StyledSignupLogoContainer, StyledSignupTitleContainer } from "./styled"
import { Desktop, Laptop } from "../../utils/responsive.util"
import { useSearchParams } from "react-router-dom"
import { Switch } from "../Switch"
import { Select } from "../Select"


const FullNameInput = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)

    const [validationMessage, setValidationMessage] = useState<any>(null)

    const onChange = (e: any) => {
        setSignupStore({...signupStore, name: e.target.value})
    }

    const onBlur = () => {
        // const name = signupStore.name
        // if (name && !name.includes(' ')) {
        //     setValidationMessage('Please enter your Full Name.')
        //     setSignupStore({...signupStore, invalidName: false})
        // }

        // if (signupStore.invalidName && name && name.includes(' ')) {
            // setSignupStore({...signupStore, invalidName: false})
        // }
    }
    
    return (
        <StyledFullNameContainer>
            <Input 
                placeholder={'Full Name'}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'90%'}
                value={signupStore.name || ''}
                onChange={onChange}
                onBlur={onBlur}
                message={validationMessage}
                setMessage={setValidationMessage}
                invalid={signupStore.invalidName}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledFullNameContainer>
    )
}

export const EmailInput = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)

    const [validationMessage, setValidationMessage] = useState<any>(null)

    useEffect(() => {
        if (validationMessage && !signupStore.email) {
            setValidationMessage(null)
        }
    }, [signupStore.invalidEmail])

    const onChange = (e: any) => {
        setSignupStore({...signupStore, email: e.target.value})
    }

    const onBlur = () => {
        const email = signupStore.email

        if (email && !email.includes('@') && !email.includes('.')) {
            setValidationMessage('Invalid Email Address.')
            setSignupStore({...signupStore, invalidEmail: true})
            return
        }

        if (signupStore.invalidEmail && email && email.includes('@') && email.includes('.')) {
            setSignupStore({...signupStore, invalidEmail: false})
            return
        }
    }


    return (
        <StyledEmailInputContainer>
            <Input 
                type={'email'}
                placeholder={'Email Address'}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'90%'}
                value={signupStore.email || ''}
                onChange={onChange}
                onBlur={onBlur}
                message={validationMessage}
                setMessage={setValidationMessage}
                invalid={signupStore.invalidEmail}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledEmailInputContainer>
    )
}

export const PasswordInput = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)

    const [validationMessage, setValidationMessage] = useState<any>(null)
    const [showPassword, setShowPassword] = useState<boolean>(false)

    useEffect(() => {
        if (validationMessage && !signupStore.password) {
            setValidationMessage(null)
        }
    }, [signupStore.invalidPassword])

    const onChange = (e: any) => {
        setSignupStore({...signupStore, password: e.target.value})
    }

    const onBlur = () => {
        const password = signupStore.password
        if (password && password.length < 8) {
            setValidationMessage('Password must be at least 8 characters')
            setSignupStore({...signupStore, invalidPassword: true})
            return
        }

        if (signupStore.invalidPassord && password) {
            setSignupStore({...signupStore, invalidPassword: false})
            return
        }
    }

    const onFocus = () => {
        setValidationMessage(null)
        setSignupStore({...signupStore, invalidPassword: false})
    }

    const onClick = () => {
        setShowPassword(!showPassword)
    }

    return (
        <StyledPasswordInputContainer>
            <Input 
                type={showPassword ? 'text' : 'password'}
                placeholder={'Password'}
                padding={[Desktop, Laptop].includes(mode) ? '12px 0px' : '16px 0px'}
                fontSize={'16px'}
                width={'90%'}
                value={signupStore.password || ''}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                message={validationMessage}
                setMessage={setValidationMessage}
                invalid={signupStore.invalidPassword}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
            <StyledShowPassword>
                <Button onClick={onClick} noStyle>
                    {showPassword ? <VisibleIcon fill="#787878" /> : <NotVisibleIcon fill="#787878"/>}
                </Button>
            </StyledShowPassword>
            
        </StyledPasswordInputContainer>
    )
}

const AccountTypeSelect = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)

    const options = [
        {
            value: 'artist',
            label: 'Artist'
        },
        {
            value: 'art_patron',
            label: 'Art Patron'
        },
        {
            value: 'gallery',
            label: 'Gallery'
        },
        {
            value: 'museum',
            label: 'Museum'
        }

    ]

    const onChange = (e: any) => {
        setSignupStore({...signupStore, role: e.value})
    }

    return (
        <StyledAccountTypeContainer>
            <Select 
                options={options} 
                onChange={onChange} 
                placeholder={'Choose Account'} 
                width={'90%'} 
                padding={[Desktop, Laptop].includes(mode) ? '4px 0px' : '8px 0px'}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledAccountTypeContainer>
    )
}

const ArtPatronSelect = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)

    const options = [
        {
            value: 'art_advisor',
            label: 'Art Advisor'
        },
        {
            value: 'art_critic',
            label: 'Art Critic'
        },
        {
            value: 'art_dealer',
            label: 'Art Dealer'
        },
        {
            value: 'art_writer',
            label: 'Art Writer'
        },
        {
            value: 'collector',
            label: 'Collector'
        },
        {
            value: 'content_creator',
            label: 'Content Creator'
        },
        {
            value: 'curator',
            label: 'Curator'
        },
        {
            value: 'interior_designer',
            label: 'Interior Designer'
        },

    ]

    const onChange = (e: any) => {
        setSignupStore({...signupStore, role: e.value})
    }

    return (
        <StyledRoleSelectContainer>
            <Select 
                options={options} 
                onChange={onChange} 
                placeholder={'Choose Art Patron'} 
                width={'90%'} 
                padding={[Desktop, Laptop].includes(mode) ? '4px 0px' : '8px 0px'}
                background={'unset'}
                border={'solid 1px #787878'}
                borderRadius="8px"
                color={'#787878'}
            />
        </StyledRoleSelectContainer>
    )
}


const RoleSwitch = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const setSignupStore = useSignupStore((state: any) => state.setSignupStore)
    const [isChecked, setIsChecked] = useState(false)

    const onChange = (e: any) => {
        setIsChecked(e)
        const roleValue = e ? 'collector' : 'artist'

        setSignupStore({...signupStore, role: roleValue})
    }
    
    
    return (
        <StyledRoleSwitchContainer>
            <Switch onChange={onChange} isChecked={isChecked}/>
        </StyledRoleSwitchContainer>
    )
}

export const SignupButton = ({invalidForm}: any) => {
    const activeState = useLandingPageStateStore((state: any) => state.activeState)
    let [searchParams] = useSearchParams();
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const resetSignupStore = useSignupStore((state: any) => state.resetSignupStore)
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)
    const setSignupComplete = useSignupCompleteStore((state: any) => state.setSignupComplete)
    const setIsButterBar = useButterBarStore((state: any) => state.setIsButterBar)
    const [disabled, setDisabled] = useState(activeState === 'createAccount')
    

    useEffect(() => {
        if (signupStore.email && signupStore.password && !signupStore.invalidName && !signupStore.invalidEmail && !signupStore.invalidPassword) {
            if (activeState === 'createAccount' && signupStore.role && signupStore.name) {
                setDisabled(false)   
            } else if (activeState === 'signIn') {
                setDisabled(false)   
            } else {
                setDisabled(true)
            }
            
        } else {
            setDisabled(true)
        }
    }, [signupStore, invalidForm])

    const onClick = async () => {
        setDisabled(true)

        const payload = {...signupStore, type: 'email', code: searchParams.get('code')}
        
        const resp = await (activeState === 'signIn' ? signin(payload) : signup(payload))

        setDisabled(false)
        
        if (resp?.status === 200) {
            if (activeState === 'createAccount') {
                setActiveState('signupComplete')
                setSignupComplete(resp.data)
            }
            resetSignupStore()
        } else {
            setIsButterBar(resp?.message)
        }

    }

    return (
        <StyledSignupButtonContainer>
            <Button 
                width={'90%'}
                height={'48px'}
                background={'#0938F0'}
                color={'#fff'}
                borderRadius={'32px'}
                fontSize={'16px'}
                onClick={onClick}
                disabled={disabled}
                hoverEffect={{background: '#2f36b1'}}
            >
                {activeState === 'signIn' ? 'Sign in' : 'Create account'}
            </Button>
        </StyledSignupButtonContainer>
    )
}

const SignupWithGoogleButton = () => {
    const activeState = useLandingPageStateStore((state: any) => state.activeState)
    let [searchParams] = useSearchParams();
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const resetSignupStore = useSignupStore((state: any) => state.resetSignupStore)
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)
    const setSignupComplete = useSignupCompleteStore((state: any) => state.setSignupComplete)
    const setIsButterBar = useButterBarStore((state: any) => state.setIsButterBar)
    const [isHover, setIsHover] = useState(false)
    const [disabled, setDisabled] = useState(activeState === 'createAccount')

    
    useEffect(() => {
        if (activeState === 'createAccount') {
            if (signupStore.role) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        } else {
            setDisabled(false)
        }
        
    }, [signupStore.role])

    const onClick = async () => {
        setDisabled(true)
        
        const payload = {...signupStore, type: 'google', code: searchParams.get('code')}
        const resp = await (activeState === 'signIn' ? signin(payload) : signup(payload))

        setDisabled(false)
        
        if (resp?.status === 200) {
            if (activeState === 'createAccount') {
                setActiveState('signupComplete')
                setSignupComplete(resp.data)
            }
            resetSignupStore()
        } else {
            setIsButterBar(resp?.message)
        }
    }

    return (
        <StyledSignupButtonContainer padding={'0px 0px 12px 0px'}>
            <Button 
                width={'90%'}
                height={'48px'}
                background={'#fff'}
                borderRadius={'32px'}
                hoverEffect={{background: '#2f36b1', color: '#fff'}}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                disabled={disabled}
                onClick={onClick}
            >
                <Icon padding={'0px 8px'}><GoogleLogo width={32} height={32} /></Icon>
                <Text fontSize={'16px'} padding={'6px 0px 0px 0px'} color={isHover ? '#fff' : '#000'}>{activeState === 'signIn' ? 'Sign in' : 'Connect'} with Google</Text>
            </Button>
        </StyledSignupButtonContainer>
    )
}

const SignupWithAppleButton = () => {
    const activeState = useLandingPageStateStore((state: any) => state.activeState)
    let [searchParams] = useSearchParams();
    const signupStore = useSignupStore((state: any) => state.signupStore)
    const resetSignupStore = useSignupStore((state: any) => state.resetSignupStore)
    const setSignupComplete = useSignupCompleteStore((state: any) => state.setSignupComplete)
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)
    const setIsButterBar = useButterBarStore((state: any) => state.setIsButterBar)
    const [isHover, setIsHover] = useState(false)
    const [disabled, setDisabled] = useState(activeState === 'createAccount')

    useEffect(() => {
        if (activeState === 'createAccount') {
            if (signupStore.role) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        } else {
            setDisabled(false)
        }
    }, [signupStore.role])

    const onClick = async () => {
        setDisabled(true)
        
        const payload = {...signupStore, type: 'apple', code: searchParams.get('code')}
        const resp = await (activeState === 'signIn' ? signin(payload) : signup(payload))

        setDisabled(false)
        
        if (resp?.status === 200) {
            if (activeState === 'createAccount') {
                setActiveState('signupComplete')
                setSignupComplete(resp.data)
            }
            resetSignupStore()
        } else {
            setIsButterBar(resp?.message)
        }
    }

    return (
        <StyledSignupButtonContainer>
            <Button 
                width={'90%'}
                height={'48px'}
                background={'#fff'}
                borderRadius={'32px'}
                hoverEffect={{background: '#2f36b1', color: '#fff'}}
                onMouseEnter={() => setIsHover(true)}
                onMouseLeave={() => setIsHover(false)}
                disabled={disabled}
                onClick={onClick}
            >
                <Icon padding={'0px 12px 0px 4px'}><AppleLogo width={24} height={24} fill={isHover ? '#fff' : '#000'}/></Icon>
                <Text fontSize={'16px'} padding={'4px 0px 0px 0px'} color={isHover ? '#fff' : '#000'}>{activeState === 'signIn' ? 'Sign in' : 'Connect'} with Apple</Text>
            </Button>
        </StyledSignupButtonContainer>
    )
}

const SignupForm = () => {
    const signupStore = useSignupStore((state: any) => state.signupStore)
    return (
        <StyledSignupFormContainer>
            <StyledSignupForm>
                <FullNameInput />
                <EmailInput />
                <PasswordInput />
                {/* <RoleSwitch /> */}
                <AccountTypeSelect />
                {signupStore.role === 'art_patron' && <ArtPatronSelect />}
                <SignupButton />
            </StyledSignupForm>
            
        </StyledSignupFormContainer>
    )
}

export const Providers = () => {
    return (
        <StyledProvidersContainer>
            <StyledProviders>
                <SignupWithGoogleButton />
                <SignupWithAppleButton />
            </StyledProviders>
        </StyledProvidersContainer>
    )
}

const SignupTitle = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    return (
        <StyledSignupTitleContainer>
            <Text 
                color={'#fff'} 
                padding={[Desktop, Laptop].includes(mode) ? '8px 0px 32px 0px' : '4px 0px 20px 0px'} 
                fontSize={[Desktop, Laptop].includes(mode) ? '24px' : '24px'} 
                fontWeight={'500'}
            >{'Create an Account'}</Text>
        </StyledSignupTitleContainer>
    )
}

const Signup = () => {
    const mode = useResponsiveStore((state: any) => state.mode)
    const setActiveState = useLandingPageStateStore((state: any) => state.setActiveState)
    return (
        <StyledSignupContainer>
            <StyledSignup>
                <SignupTitle />
                <SignupForm />
                <Text color="#fff" textAlign={'center'} fontSize={'16px'} padding={[Desktop, Laptop].includes(mode) ? '24px 0px' : '0px'}>or</Text>
                <Providers />
                <StyledBackToSignIn>
                    <Text color="#958D8C" fontWeight="600" textAlign={[Desktop, Laptop].includes(mode) ? 'left' : 'center'}>{'Already have a Primary Yellow Account?'}</Text>
                    <Button noStyle fontWeight="500" onClick={() => setActiveState('signIn')}>Sign In</Button>
                </StyledBackToSignIn>
            </StyledSignup>
        </StyledSignupContainer>
    )
}

export default Signup