import styled from 'styled-components'


interface TextProps {
    theme: any;
    color?: string;
    fontSize?: string;
    fontWeight?: string;
    padding?: string;
    textAlign?: string;
    textDecoration?: string;
}


export const StyledText = styled.div<TextProps>`
    font-family: ${props => props.theme.global.fontFamily};
    color: ${props => props.color || props.theme.text.color};
    font-size: ${props => props.fontSize ||  '13px'};
    font-weight: ${props => props.fontWeight ||  'normal'};
    padding: ${props => props.padding ||  '0'};
    text-align: ${props => props.textAlign ||  'left'};
    text-decoration: ${props => props.textDecoration || props.theme.text.textDecoration};
`