import styled from 'styled-components'


interface FooterBottomContainerProps {

}


export const StyledFooterBottomContainer = styled.div<FooterBottomContainerProps>`
    border-top: solid 1px #D7D7D7;
    padding: 12px;
    

    @media (min-width: 768px) {
        padding: 36px 42px;
        
    }

    @media (min-width: 1024px) {

    }
`

interface FooterBottomProps {

}


export const StyledFooterBottom = styled.div<FooterBottomProps>`
    width: 100%;

    @media (min-width: 768px) {
        display: inline-flex;
        align-items: center;
        
    }

    @media (min-width: 1024px) {

    }
    
`

interface FooterBottomLeftContainerProps {

}


export const StyledFooterBottomLeftContainer = styled.div<FooterBottomLeftContainerProps>`
    width: 100%;

    @media (min-width: 768px) {
        width: 75%;
        
    }

    @media (min-width: 1024px) {

    }
`

interface FooterBottomLeftProps {

}


export const StyledFooterBottomLeft = styled.div<FooterBottomLeftProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: left;
    gap: 24px;

    @media (min-width: 768px) {
        padding: 0;
    }

    @media (min-width: 1024px) {

    }
`

interface FooterBottomRightContainerProps {

}


export const StyledFooterBottomRightContainer = styled.div<FooterBottomRightContainerProps>`
    width: 100%;
    padding: 24px 0px 12px 0px;

    @media (min-width: 768px) {
        width: 25%;
        padding: 0;
    }

    @media (min-width: 1024px) {

    }
`

interface FooterBottomRightProps {

}


export const StyledFooterBottomRight = styled.div<FooterBottomRightProps>`
    display: inline-flex;
    width: 100%;
    align-items: center;
    gap: 24px;
    justify-content: center;

    @media (min-width: 768px) {
        justify-content: right;
    }

    @media (min-width: 1024px) {

    }
`