import { ReactNode } from 'react'

import { StyledButton, StyledButtonContent, StyledButtonContentContainer, StyledButtonContentWrapper } from "./styled"
import { useThemeStore } from '../../stores/web.store'


type ButtonProps = {
    children?: ReactNode;
    noEffect?: boolean;
    onClick?: any;
    noStyle?: boolean;
    background?: string;
    border?: string;
    borderRadius?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string;
    width?: string;
    height?: string;
    textAlign?: string;
    contentDisplay?: string;
    disabled?: boolean;
    hoverEffect?: any
    onMouseEnter?: any;
    onMouseLeave?: any;
}


const Button = (props: ButtonProps) => {
    const theme = useThemeStore((state: any) => state.theme)

    return (
        <StyledButton 
            theme={theme}
            background={props.background}
            noEffect={props.noEffect}
            border={props.border}
            borderRadius={props.borderRadius}
            padding={props.padding}
            fontSize={props.fontSize}
            fontWeight={props.fontWeight}
            width={props.width}
            height={props.height}
            color={props.color}
            textAlign={props.textAlign}
            noStyle={props.noStyle}
            onClick={props.onClick}
            disabled={props.disabled}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
            hoverEffect={props.hoverEffect}
        >
            <StyledButtonContentContainer theme={theme} height={props.height}>
                <StyledButtonContent theme={theme} height={props.height}>
                    <StyledButtonContentWrapper>
                        {props.children}
                    </StyledButtonContentWrapper>
                </StyledButtonContent>
            </StyledButtonContentContainer>
        </StyledButton>
    )
}

export default Button