import styled from 'styled-components'


interface LayoutProps {

}


export const StyledLayout = styled.div<LayoutProps>`
    height: 100vh;
    width: 100%;
    background: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    // font-family:"ll-unica77","Helvetica Neue",Helvetica,Arial,sans-serif;
    font-family:"Avenir","Helvetica Neue",Helvetica,Arial,sans-serif;
`