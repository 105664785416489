import styled from 'styled-components'


interface TopNavContainerProps {

}


export const StyledTopNavContainer = styled.div<TopNavContainerProps>`
    width: 100%;
    position: fixed;
    background: #fff;
`

interface TopNavWrapperProps {

}


export const StyledTopNavWrapper = styled.div<TopNavWrapperProps>`
    padding: 0px 32px;
`

interface TopNavProps {

}


export const StyledTopNav = styled.div<TopNavProps>`
    height: 84px;
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 25% 50% 25%;
    grid-gap: 0;
`

interface TopNavLeftSectionProps {

}


export const StyledTopNavLeftSection = styled.div<TopNavLeftSectionProps>`
    display: table;
    width: 100%;
`

interface TopNavMiddleSectionProps {

}


export const StyledTopNavMiddleSection = styled.div<TopNavMiddleSectionProps>`
    text-align: center;
    display: table;
    width: 100%;
`

interface TopNavRightSectionProps {

}


export const StyledTopNavRightSection = styled.div<TopNavRightSectionProps>`
    text-align: right;
    display: table;
    width: 100%;
`


interface TopNavVerticalAlignMiddleProps {

}


export const StyledTopNavVerticalAlign = styled.div<TopNavVerticalAlignMiddleProps>`
    display: table-cell;
    height: 64px;
    vertical-align: middle;
    width: 100%;
    position: relative;
`

interface TopNavigationMenuProps {

}


export const StyledTopNavigationMenu = styled.div<TopNavigationMenuProps>`
    width: 100%;
    margin: auto;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-gap: 0;
    padding: 40px 0px
`


interface NavigationMenuLeftSectionProps {

}


export const StyledNavigationMenuLeftSection = styled.div<NavigationMenuLeftSectionProps>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
`

interface NavigationMenuRightSectionProps {

}


export const StyledNavigationMenuRightSection = styled.div<NavigationMenuRightSectionProps>`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    width: 100%;
    text-align: right;
`

interface MenuItemProps {
    textAlign?: string;
}


export const StyledMenuItem = styled.div<MenuItemProps>`
    padding: 0px 12px;
    text-align: ${props => props.textAlign || 'left'};
    margin: auto;
`

interface AccountMenuContainerProps {

}


export const StyledAccountMenuContainer = styled.div<AccountMenuContainerProps>`
    position: absolute;
    top: 8px;
    right: 32px;
    padding: 24px;
    height: fit-content;
    width: fit-content;
    background: none;
    z-index: 2;
    text-align: left;

`

interface AccountMenuProps {

}


export const StyledAccountMenu = styled.div<AccountMenuProps>`

`
