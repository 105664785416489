import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'

import Routing from './routing'
import { useResponsiveStore } from './stores/web.store';
import { Desktop, Laptop, Mobile } from './utils/responsive.util';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

const App = () => {
  const setMode = useResponsiveStore((state: any) => state.setMode)
  const setHeight = useResponsiveStore((state: any) => state.setHeight)
  const setWidth = useResponsiveStore((state: any) => state.setWidth)
  const mode = useResponsiveStore((state: any) => state.mode)

  useEffect(() => {

    const _setMode = (width: number, height: number) => {
      setHeight(height)
      setWidth(width)
      
      if (width <= 768) {
        if (mode !== Mobile) {
          setMode(Mobile)
          return
        }
      }
      if (width > 768 && width <= 1028) {
        if (mode !== Laptop) {
          setMode(Laptop)
          return
        }
      }
      if (width > 1028 && width < 1400) {
        if (mode !== Desktop) {
          setMode(Desktop)
          return
        } 
      } 
      return
    } 

    _setMode(window.innerWidth, window.innerHeight)
    
    const handleWindowResize = () => {
      _setMode(window.innerWidth, window.innerHeight)
    }

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <BrowserRouter>
          <Routing />
      </BrowserRouter>
    </QueryClientProvider>
  );
}

export default App;