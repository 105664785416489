import { FooterBottom } from "./FooterBottom"
import { FooterContent } from "./FooterContent"
import { StyledFooter, StyledFooterContainer } from "./styled"


const Footer = () => {
    return (
        <StyledFooterContainer>
            <StyledFooter>
                <FooterContent />
                <FooterBottom />
            </StyledFooter>
        </StyledFooterContainer>
    )
}

export default Footer