import styled from 'styled-components'


interface ButtonProps {
    theme: any;
    noStyle?: boolean;
    noEffect?: boolean;
    background?: string;
    border?: string;
    borderRadius?: string;
    padding?: string;
    fontSize?: string;
    fontWeight?: string;
    color?: string
    width?: string;
    height?: string;
    textAlign?: string;
    disbaled?: boolean
    hoverEffect?: any;
}


export const StyledButton = styled.button<ButtonProps>`
    cursor: pointer;
    font-family: ${props => props.theme.global.fontFamily};
    background: ${props => props.background || (props.noStyle ? 'unset' : props.theme.button.background)};
    border: ${props => props.border || (props.noStyle ? 'unset' : props.theme.button.border)};
    border-radius: ${props => props.borderRadius || (props.noStyle ? 'unset' : props.theme.button.borderRadius)};
    color: ${props => props.color || props.theme.button.color};
    padding: ${props => props.padding || (props.noStyle ? '0' : props.theme.button.padding)};
    font-size: ${props => props.fontSize || props.theme.button.fontSize};
    text-align: ${props => props.textAlign || 'center'};
    font-weight: ${props => props.fontWeight || props.theme.button.fontWeight};
    width: ${props => props.width || props.theme.button.width};
    height: ${props => props.height || props.theme.button.height};

    &:focus {
        outline: none;
    }

    ${props => props.disabled && `
        background: rgba(207, 207, 207, 1);
        cursor: not-allowed;
        border: rgba(207, 207, 207, 1);
        color: #000;
    `};

    ${props => !props.disabled && props.hoverEffect && `
        &:hover {
            background: ${props.hoverEffect.background || 'none'};
            color: ${props.hoverEffect.color || props.color || props.theme.button.color};
            border: 1px solid rgba(255, 255, 255, 0);
        }
    `};
`

interface ButtonContentContainerProps {
    theme: any;
    height?: string;
}


export const StyledButtonContentContainer = styled.div<ButtonContentContainerProps>`
    
    height: ${props => props.height || props.theme.button.height};
    width: 100%;
`

interface ButtonContentProps {
    theme: any;
    height?: string;
}


export const StyledButtonContent = styled.div<ButtonContentProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: ${props => props.height || props.theme.button.height};
`

interface ButtonContentWrapperProps {

}


export const StyledButtonContentWrapper = styled.div<ButtonContentWrapperProps>`
    display: inline-flex;
`