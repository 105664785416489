import {create} from 'zustand'
import { LightTheme } from '../themes/web.theme'


export const useAccessStore = create((set) => ({
    access: null,
    setAccess: (data: string) => set(() => ({ access: data }))
}))

export const useSuperAdminAccessStore = create((set) => ({
    isSuperAdmin: false,
    setIsSuperAdmin: (data: string) => set(() => ({ isSuperAdmin: data }))
}))

export const useThemeStore = create((set) => ({
    theme: LightTheme(),
    setTheme: (data: string) => set(() => ({ theme: data }))
}))

export const useResponsiveStore = create((set) => ({
    mode: 'desktop',
    height: 1200,
    width: 1440,
    setMode: (data: string) => set(() => ({ mode: data })),
    setHeight: (data: string) => set(() => ({ height: data })),
    setWidth: (data: string) => set(() => ({ width: data }))
}))

const DEFAULT_SIGNUP_STORE = {email: null, password: null, name: null, role: null, invalidEmail: false, invalidPassword: false, invalidName: false}

export const useSignupStore = create((set) => ({
    signupStore: DEFAULT_SIGNUP_STORE,
    setSignupStore: (data: string) => set(() => ({ signupStore: data })),
    resetSignupStore: () => set(() => ({ signupStore: DEFAULT_SIGNUP_STORE}))
}))

export const useTokenStore = create((set) => ({
    token: null,
    setToken: (data: string) => set(() => ({ token: data }))
}))

export const useSignupCompleteStore = create((set) => ({
    signupComplete: false,
    setSignupComplete: (data: string) => set(() => ({ signupComplete: data })),
}))

export const useButterBarStore = create((set) => ({
    isButterBar: false,
    setIsButterBar: (data: string) => set(() => ({ isButterBar: data })),
}))

export const useLandingPageStateStore = create((set) => ({
    activeState: 'initial',
    setActiveState: (data: string) => set(() => ({ activeState: data })),
}))


  
  
