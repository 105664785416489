import { useEffect, useState } from "react";
import { useThemeStore } from "../../stores/web.store";
import { StyledInput, StyledInputContainer, StyledLabel, StyledValidationMessage, StyledValidationMessageContainer } from "./styled"


type ValidationMessageProps = {
    message?: string;
}


const ValidationMessage = ({message}: ValidationMessageProps) => {
    return (
        <StyledValidationMessageContainer>
            <StyledValidationMessage>
                {message}
            </StyledValidationMessage>
        </StyledValidationMessageContainer>
    )
}


type InputProps = {
    type?: string;
    placeholder?: string;
    padding?: string;
    border?: string;
    borderRadius?: string;
    background?: string;
    color?: string;
    width?: string;
    fontSize?: string;
    message?: any;
    setMessage?: any;
    invalid?: boolean;
    value?: any;
    id?: string;
    onChange?: any;
    onBlur?: any;
    onFocus?: any;
    outline?: string;
}

const Input = (props: InputProps) => {
    const theme = useThemeStore((state: any) => state.theme)
    const [isFocus, setIsFocus] = useState(false)

    useEffect(() => {
        if (props.message) {
            setTimeout(() => {
                props.setMessage(null)
            }, 3000);
        }
    }, [props.message])

    const onFocus = () => {
        setIsFocus(true)
        if (props.onFocus) {
            props.onFocus()
        }
    }

    const onBlur = () => {
        if (props.onBlur) {
            props.onBlur()
        }
       
        setIsFocus(false)
    }

    return (
        <StyledInputContainer>
            {/* {props.placeholder && <StyledLabel theme={theme} isFocus={isFocus} value={props.value} invalid={props.invalid}>{props.placeholder}</StyledLabel>} */}
            <StyledInput 
                id={props.id}
                theme={theme}
                type={props.type || 'text'}
                placeholder={isFocus ? `Enter your ${props.placeholder}` : props.placeholder}
                padding={props.padding}
                border={props.border}
                outline={props.outline}
                color={props.color}
                borderRadius={props.borderRadius}
                background={props.background}
                fontSize={props.fontSize}
                width={props.width}
                value={props.value}
                onInput={props.onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                invalid={props.invalid}
            />
            {props.message && <ValidationMessage message={props.message} />}
        </StyledInputContainer>
    )
}

export default Input